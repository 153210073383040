import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from '../assets/Chasylmlogo.png'

// import { IoIosSearch } from "react-icons/io";
import { VscAccount } from "react-icons/vsc";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";


function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="nav-container">
      <div className="logo">      
        <Link className='li-links' to='/home'>
        <img className='img_logo' style={{height:'15vh'}} src={logo} alt="" />
          {/* <p className='logo-title'>CHASLYM TOURS</p> */}
        </Link>
      </div>

      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <ul className='ul-links'>
          <Link className='li-links' to='/home'><li>Home</li></Link>
          <Link className='li-links'>
            <li className='drop-menu'>Pages
              <ul className='dropdown'>
                <Link className='li-links' to='/about-us'><li>About Us</li></Link>
                <Link className='li-links' to='/what-we-offer'><li>What We Offer</li></Link>
                <Link className='li-links' to='/contact-us'><li>Contact Us</li></Link>
                <Link className='li-links' to='/faq'><li>FAQ Page</li></Link>
              </ul>
            </li>
          </Link>
          <Link className='li-links' to='/destinations'><li>Destinations</li></Link>
          <Link className='li-links' to='/tours'><li>Tour Packages</li></Link>
          <Link className='li-links' to='/blogs'><li>Blog</li></Link>

          {/* <Link className='li-links' to=''><li><IoIosSearch className="nav-icon"/></li></Link> */}

           <Link className='li-links'>
            <li className='drop-menu'>
              <VscAccount className="nav-icon"/>
              <ul className='dropdown'>
                <Link className='li-links' to='/login'><li>Login</li></Link>
                <Link className='li-links' to='/register'><li>Sign up</li></Link>
              </ul>
            </li>
          </Link>
          
        </ul>
      </div>

     
      
      <div>
      <ul>
        <li className='hamburger-menu' onClick={toggleMenu}>
            {isMenuOpen ? <AiOutlineClose className="nav-icon"/> : <GiHamburgerMenu className="nav-icon"/>}
          </li>
          </ul>  
      </div>
    </div>
  );
}

export default Navbar;
