import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './TourPackage.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Newsletter from './Newsletter';
import { CiClock2 } from 'react-icons/ci';
import { IoPricetagsOutline } from 'react-icons/io5';
import { CiStar } from 'react-icons/ci';
import { IoPersonOutline } from 'react-icons/io5';
import { Pane, Tablist, Tab, Button, Spinner } from 'evergreen-ui';
import { HiOutlineInformationCircle } from "react-icons/hi2";
import {  FaMapSigns, FaImages } from 'react-icons/fa';


const Tabs = ({ tour }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = [
    { label: 'Information', icon: <HiOutlineInformationCircle  style={{paddingRight:"5px", fontSize:'23px'}}/> },
    { label: 'Travel Plan', icon: <FaMapSigns style={{paddingRight:"5px", fontSize:'23px'}}/> },
    { label: 'Gallery', icon: <FaImages style={{paddingRight:"5px", fontSize:'23px'}}/> },];

  const renderContent = () => {
    switch (tabs[selectedIndex].label.toLowerCase()) {
      case 'information':
        return (
          <Pane>
            <h2 className='title-secondary'>Overview</h2>
            <p className='txt_p'>{tour.info_overview}</p>
            <h2 className='title-primary'>Highlight</h2>
            {Array.isArray(tour.info_highlight) && tour.info_highlight.length > 0 ? (
              tour.info_highlight.map((highlight, index) => (
                <p key={index}>{highlight}</p>
              ))
            ) : (
              <p className='txt_p'>{tour.info_highlight}</p>
            )}
            <table>
              <tbody>
                <tr>
                  <th>Destination:</th>
                  <td className='txt_p'>{tour.destination_name}</td>
                </tr>
                <tr>
                  <th>Departure:</th>
                  <td className='txt_p'>{tour.departure}</td>
                </tr>

                <tr>
  <th>Included:</th>
  <td>
    <ol>
      {tour.included ? (
        tour.included
          .split(',') // Split the string by commas into an array
          .map((item, index) => (
            <li key={index}>{item.trim()}</li> // Trim to remove any extra spaces
          ))
      ) : (
        <li className='txt_p'>No items included</li>
      )}
    </ol>
  </td>
</tr>

<tr>
  <th>Excluded:</th>
  <td>
    <ol>
      {tour.excluded ? (
        tour.excluded
          .split(',') // Split the string by commas into an array
          .map((item, index) => (
            <li key={index}>{item.trim()}</li> // Trim to remove any extra spaces
          ))
      ) : (
        <li className='txt_p'>No items excluded</li>
      )}
    </ol>
  </td>
</tr>

                    </tbody>
            </table>
          </Pane>
        );
      case 'travel plan':
       
      return (
        <Pane>
  <h2 className='title-secondary'>Travel Plan</h2>
<p className='txt_p'>{tour.travel_plan_overview}</p>

<h2 className='title-primary'>Highlights</h2>

{/* Loop through travel plan titles and descriptions */}
{[...Array(7).keys()].map((_, index) => {
  const dayTitleKey = `travel_plan_day_title_${index + 1}`;
  const dayDescriptionKey = `travel_plan_day_description_${index + 1}`;
  
  // Only render the day title and description if the title exists in the database
  return (
    tour[dayTitleKey] && (
      <div key={index}>
        <h3 className='title-tertiary'>
          {tour[dayTitleKey]}
        </h3>
        <p className='txt_p'>{tour[dayDescriptionKey]}</p>
      </div>
    )
  );
})}

{/* Existing travel_plan_day_title handling */}
{Array.isArray(tour.travel_plan_day_title) && tour.travel_plan_day_title.length > 0 ? (
  tour.travel_plan_day_title.map((dayTitle, index) => (
    <p key={index}>{dayTitle}</p>
  ))
) : (
  <p className='txt_p'>{tour.travel_plan_day_title}</p>
)}

<table>
  <tbody>
    <tr>
      <td className='txt_p'>{tour.travel_plan_day_description}</td>
    </tr>
  </tbody>
</table>

</Pane>

    );
      case 'gallery':
        return (
          <Pane>
            <h2 className='title-secondary'>Gallery</h2>
            {/* Array to hold image gallery fields */}
            <div className="gallery">
  {/* Array to hold image gallery fields */}
  {Array.from({ length: 9 }, (_, index) => {
    const imageKey = `image_gallery_${index + 1}`;
    
    // Check if the image is a valid URL string
    return tour[imageKey] && (
      <img
        key={index}
        src={`${process.env.REACT_APP_BASE_URL}/${tour[imageKey]}`} // Append the base URL to the image path
        alt={`gallery-${index + 1}`}
        className="gallery-image"
      />
    );
  })}
</div>


          </Pane>
        );
      default:
        return null;
    }
  };

  return (
    <Pane>
      <Tablist marginBottom={16}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            isSelected={index === selectedIndex}
            onSelect={() => setSelectedIndex(index)}
            aria-controls={`panel-${tab.label}`}

            style={{
              backgroundColor: index === selectedIndex ? '#f97316' : '#E6E8F0', // Active and inactive tab background colors
              color: index === selectedIndex ? '#FFFFFF' : '#000000', // Active and inactive tab text colors
              padding: '25px 30px', // Padding inside the tab
              borderRadius: '5px', // Rounded corners for tabs
              margin: '0 5px', // Space between tabs
              fontWeight: index === selectedIndex ? 'bold' : 'normal', // Bold text for active tab
              fontSize:"18px"
            }}
          >
            {tab.icon} {tab.label}
          </Tab>
        ))}
      </Tablist>
      <Pane padding={16} background="tint1">
        {renderContent()}
      </Pane>
    </Pane>
  );
};

function TourPackage() {
  const [searchParams] = useSearchParams();
    const tourId = searchParams.get("id"); // Get the tour ID from query params

    const [tour, setTour] = useState(null);

    useEffect(() => {
      const fetchTour = async () => {
          try {
              const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours/${tourId}`);
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setTour(data);

               // Set tour.destination_name and tourId in formData when tour is fetched
        setFormData((prevState) => ({
          ...prevState,
          destination_name: data.destination_name,
          tourId: tourId,
        }));

          } catch (error) {
              console.error('Error fetching tour:', error);
          }
      };

      if (tourId) {
          fetchTour();
      }
  }, [tourId]);

   // State for booking form
   const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    paymentType: 'full' ,
    adult: 0,
    child: 0,
    date: '',
    amount: '',
    message: '',
    destination_name: '', // Add destination_name field
    tourId: '', // Add tourId field
  });

  const [loading, setLoading] = useState(false); // Loading state for the button spinner

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Prevent negative values for adult, child
    if (name === 'adult' || name === 'child') {
      const intValue = Math.max(0, parseInt(value) || 0);
      setFormData((prevState) => ({
        ...prevState,
        [name]: intValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  
    setFormData((prevState) => {
      const totalAdults = parseInt(prevState.adult || 0);
      const totalChildren = parseInt(prevState.child || 0);
      const calculatedAmount = tour.price * (totalAdults + totalChildren);
  
      // Determine if payment is full or deposit and calculate remaining amount
      const isDeposit = prevState.paymentType === 'deposit';
      const paidAmount = isDeposit ? calculatedAmount * 0.3 : calculatedAmount;
      const remainingAmount = isDeposit ? (calculatedAmount - paidAmount).toFixed(2).toString() : "0"; // Convert to string
  
      return {
        ...prevState,
        amount: paidAmount.toFixed(2),
        remainingAmount: remainingAmount, // Store remaining amount for deposit
      };
    });
  };


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure amount is not empty or zero
    if (!formData.amount || parseFloat(formData.amount) === 0) {
      alert('Amount is required and must be greater than zero.');
      return;
    }

    setLoading(true); // Show spinner when submitting

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pesapal/submit-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Send form data to backend
      });

      const data = await response.json();
      console.log('Order submitted:', data);

      if (data.redirect_url) {
        window.location.href = data.redirect_url; // Redirect to provided URL
      } else {
        console.error('Redirect URL not found in response:', data);
      }
    } catch (error) {
      console.error('Error submitting order:', error);
    } finally {
      setLoading(false); // Hide spinner when submission is done
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  if (!tour) {
    return <div>Loading...</div>;
  }

  return (
    <div className="package_container">
      <div className="package-sect">
        <p className="banner-title">Tour Packages</p>
      </div>

      <div data-aos="zoom-in" className="package_sides">
        <div className="rt_package">
          <div className="image-holder">
            <img src={`${process.env.REACT_APP_BASE_URL}/${tour.image_preview}`} alt={tour.destination_name} className="img-package" />
          </div>
          <div className="package-titles">
            <p className='card__title' style={{color:'black', fontWeight:"700"}}>{tour.destination_name}, {tour.destination_title}</p>
          </div>

          <hr style={{ margin: '20px 0', backgroundColor: '#ccc', border: 'none', height: '1px' }} />

          <div className="info-breaker">
            <div className="package-icons">
              <CiClock2 className='icon-package'/>
              <div>
                <p className='title-primary' style={{fontSize:"17px",fontWeight:"700"}}>Duration</p>
                <p className='txt_p'style={{fontSize:"15px",fontWeight:"800", padding:"3px"}}>{tour.duration} days</p>
              </div>
            </div>
            <div className="package-icons">
              <IoPricetagsOutline className='icon-package'/>
              <div>
                <p className='title-primary' style={{fontSize:"17px",fontWeight:"700"}}>Price</p>
                <p className='txt_p' style={{fontSize:"15px",fontWeight:"800", padding:"3px"}}>${tour.price}</p>
              </div>
            </div>
            <div className="package-icons">
              <CiStar className='icon-package'/>
              <div>
                <p className='title-primary' style={{fontSize:"17px",fontWeight:"700"}}>Rating</p>
                <p className='txt_p' style={{fontSize:"15px",fontWeight:"800", padding:"3px"}}>8K+ </p>
              </div>
            </div>
            <div className="package-icons">
              <IoPersonOutline className='icon-package'/>
              <div>
                <p className='title-primary' style={{fontSize:"17px",fontWeight:"700"}}>Age</p>
                <p className='txt_p' style={{fontSize:"15px",fontWeight:"800", padding:"3px"}}>2+</p>
              </div>
            </div>
          </div>

          <hr style={{ margin: '20px 0', backgroundColor: '#ccc', border: 'none', height: '1px' }} />

          <Tabs tour={tour} />
        </div>

        <div className="lt_package">
          <p>Book this package</p>
          <form onSubmit={handleSubmit} className="booking-inputs">
            <input type="text" name="firstName" required='true' value={formData.firstName} onChange={handleChange} placeholder="First Name" />
            <input type="text" name="lastName" required='true' value={formData.lastName} onChange={handleChange} placeholder="Last Name" />
            <input type="email" name="email" required='true' value={formData.email} onChange={handleChange} placeholder="Your Email" />
            <input type="tel" name="phone" required='true' value={formData.phone} onChange={handleChange} placeholder="Phone" />
            <input type="number" name="adult" value={formData.adult} onChange={handleChange} placeholder="Adult" />
            <input type="number" name="child" value={formData.child} onChange={handleChange} placeholder="Child" />
            <input type="date" name="date" required='true' value={formData.date} onChange={handleChange} placeholder="Date" />

            {/* Payment type selection */}
            <div>
             <p className='txt_cont'>
                <input
                  type="radio"
                  name="paymentType"
                  value="full"
                  checked={formData.paymentType === 'full'}
                  onChange={handleChange}
                />
                Pay Full Amount
              </p>
              
            <p className='txt_cont'>
                <input
                  type="radio"
                  name="paymentType"
                  value="deposit"
                  checked={formData.paymentType === 'deposit'}
                  onChange={handleChange}
                />
                Pay 30% Deposit

              </p>
              
            </div>

            {/* <p>Total Amount: ${formData.amount}</p> */}
            
    <p>Total Amount: ${formData.amount}</p>
        {formData.paymentType === 'deposit' && (
       <p>Remaining Amount: ${formData.remainingAmount}</p>
  )}

            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              style={{ height: "10rem", textAlign: "start", resize: "vertical", width: "90%" }} rows={10}
            />

            <Button appearance="primary" type="submit" isLoading={loading}>
              {loading ? <Spinner /> : 'Book Now'}
            </Button>
          </form>
        </div>
      </div>
      <Newsletter />
    </div>
  );
}

export default TourPackage;
